'use client';
import Link from 'next/link';
import { Menu, X } from 'lucide-react';
import { useState, useEffect } from 'react';
import {
  SignInButton,
  SignedIn,
  SignedOut,
  UserButton,
  useAuth,
} from '@clerk/nextjs';
import Image from 'next/image';

export function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isLoaded } = useAuth();

  const navItems = [
    { href: '/record', label: 'Streaming' },
    { href: '#how-it-works', label: 'How It Works' },
    { href: '#features', label: 'Features' },
    // { href: '#testimonials', label: 'Testimonials' },
    { href: 'https://calendly.com/sas70/translate-30-min', label: 'Contact Us', external: true }
  ];

  if (!isLoaded) {
    return (
      <header className='sticky top-0 z-50 w-full bg-teal-700 backdrop-blur-sm'>
        <div className='container mx-auto px-4 md:px-6'>
          <div className='flex h-20 items-center'>
            <div className='flex items-center gap-2'>
              <Image src='/logo.png' alt='Logo' width={32} height={32} />
              <span className='text-2xl font-bold text-white'>
                TalkTranslate
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }

  return (
    <header className='sticky top-0 z-50 w-full bg-teal-700 backdrop-blur-sm'>
      <div className='container mx-auto px-4 md:px-6'>
        <div className='flex h-20 items-center justify-between'>
          <div className='flex items-center gap-1'>
            <Image src='/logo.png' alt='Logo' width={42} height={38} />
            <Link href='/' className='text-2xl font-bold text-white'>
              TalkTranslate
            </Link>
          </div>

          {/* Desktop Navigation */}
          <nav className='hidden md:flex gap-8 items-center'>
            {navItems.map((item) => (
              <div key={item.href}>
                {item.href === '/record' ? (
                  <SignedIn>
                    <Link
                      href={item.href}
                      className='text-lg font-semibold text-gray-100 hover:text-green-100 transition-colors mt-[5px]'
                    >
                      {item.label}
                    </Link>
                  </SignedIn>
                ) : (
                  <Link
                    href={item.href}
                    className='text-lg font-semibold text-gray-100 hover:text-green-100 transition-colors mt-[5px]'
                    target={item.external ? '_blank' : undefined}
                    rel={item.external ? 'noopener noreferrer' : undefined}
                  >
                    {item.label}
                  </Link>
                )}
              </div>
            ))}
            <div className='mt-[5px]'>
              <SignedOut>
                <SignInButton mode='modal'>
                  <button className='bg-white text-teal-700 hover:bg-teal-100 px-4 py-2 rounded-md transition-colors'>
                    Sign In
                  </button>
                </SignInButton>
              </SignedOut>
              <SignedIn>
                <UserButton
                  // afterSignOutUrl='/'
                  appearance={{
                    elements: {
                      // Style the container of the entire dropdown
                      card: 'bg-white shadow-xl rounded-xl border-0 bg-gradient-to-t from-green-100 to-white',

                      // Style the user info section
                      userPreviewMainIdentifier: 'text-gray-900 font-semibold',
                      userPreviewSecondaryIdentifier: 'text-gray-500',

                      // Style the avatar
                      avatarBox: 'w-10 h-10',

                      // Style the menu items
                      userButtonPopoverActionButton:
                        'hover:bg-green-50 text-gray-700',
                      userButtonPopoverActionButtonText: 'font-medium',
                      userButtonPopoverFooter: 'hidden', // Hide the Clerk branding

                      // Style individual buttons
                      userPreviewAvatarBox: 'border-2 border-teal-100',

                      // Style the manage account and sign out buttons
                      userButtonPopoverMain: 'p-2',
                      userButtonPopoverActions: 'space-y-1',
                      userButtonPopoverActionButtonIcon: 'text-teal-600',

                      // Divider between sections
                      userButtonPopoverActionButtonArrow: 'text-gray-400',

                      // Hide development mode badge
                      badge: 'hidden',
                    },
                    layout: {
                      // Customize spacing and layout
                    },
                  }}
                />
              </SignedIn>
            </div>
          </nav>

          {/* Mobile Menu Button - Only show for signed-in users */}
          <SignedIn>
            <button
              className='md:hidden p-2'
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? (
                <X className='h-6 w-6 text-white' />
              ) : (
                <Menu className='h-6 w-6 text-white' />
              )}
            </button>
          </SignedIn>

          {/* Mobile Sign In Button */}
          <SignedOut>
            <div className='md:hidden'>
              <SignInButton mode='modal'>
                <button className='bg-white text-teal-700 hover:bg-teal-100 px-4 py-2 rounded-md transition-colors'>
                  Sign In
                </button>
              </SignInButton>
            </div>
          </SignedOut>
        </div>
      </div>

      {/* Mobile Navigation Menu */}
      {isMenuOpen && (
        <div className='md:hidden bg-teal-700 border-b border-teal-600'>
          <nav className='flex flex-col p-4'>
            {navItems.map((item) => (
              <div key={item.href}>
                {item.href === '/record' ? (
                  <SignedIn>
                    <Link
                      href={item.href}
                      className='block py-2 text-lg font-semibold text-gray-100 hover:text-green-100 transition-colors'
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {item.label}
                    </Link>
                  </SignedIn>
                ) : (
                  <Link
                    href={item.href}
                    className='block py-2 text-lg font-semibold text-gray-100 hover:text-green-100 transition-colors'
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {item.label}
                  </Link>
                )}
              </div>
            ))}
            <SignedIn>
              <div className='py-2 border-t border-teal-600 mt-2'>
                <UserButton
                  appearance={
                    {
                      /* ... existing appearance config ... */
                    }
                  }
                />
              </div>
            </SignedIn>
          </nav>
        </div>
      )}
    </header>
  );
}
